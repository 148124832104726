<template>
  <r-e-dialog
      title="添加黑名单"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="600px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
<!--表单-->
    <el-form :rules="rules" ref="form" :model="formdata"   >
      <el-form-item label="姓名" prop="name" label-width="100px" size="small">
          <el-input  v-model="formdata.name" style="width: 400px" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="证件号码" prop="idCard" label-width="100px" size="small">
          <el-input  v-model="formdata.idCard" style="width: 400px" placeholder="请输入证件号码"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" label-width="100px" size="small">
          <el-input  v-model="formdata.phone" style="width: 400px" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="原因" prop="reason" label-width="100px" size="small">
        <el-input v-model="formdata.reason" type="textarea" style="width: 400px" :rows="2" placeholder="请输入原因"/>
      </el-form-item>
    </el-form>

  </r-e-dialog>
</template>

<script>
import {risedialogapi,risedialogapixiangqing,getBlackAddAPI} from "@/api/tenant-management";
import {extractUrlParameter} from "@storage/cookies";
import { MessageSuccess } from "@custom/message";
export default {
  name: "dialog-add-blacklist",
  data() {
    return {
      uuid:'',
      addDialogVisible: false,
      type: '1',
      formdata:{
        name:'',//姓名
        idCard:'',//证件号码
        phone:'',//手机号
        reason:'',//原因
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        idCard: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
        ],
        reason: [
          { required: true, message: '请输入原因', trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    // 抬头回显，如果是设置过的，就让他回显
    openblackclick(row){
      console.log(row)
      this.addDialogVisible = true
    },
    // 弹框确认
    clickSubmit(){
      console.log(this.formdata)
      this.$refs.form.validate((valid) => {
        if (valid) {
          let {name,idCard,phone,reason} = this.formdata
          console.log(this.formdata)
          const loadingOptions = this.loadingOptions;
          const loading = this.$loading({...loadingOptions});
          getBlackAddAPI({name,idCard,phone,reason}).then(res => {
            console.log(res)
            MessageSuccess('添加成功');
            this.$emit('handleSearch');
            this.clickCancel();
          }).finally(() => loading.close());
        } 
      });
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
      this.formdata = {}

    },
  }
}
</script>

<style scoped lang="scss">
.invoice_top{
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
</style>